<template>
    <div>
        <v-layout wrap justify-center>
            <v-snackbar v-model="showSnackBar" color="black">
                <v-layout wrap justify-center>
                    <v-flex text-left class="align-self-center">
                        <span style="color: white">
                            {{ msg }}
                        </span>
                    </v-flex>
                    <v-flex text-right>
                        <v-btn small :ripple="false" text @click="showSnackBar = false">
                            <v-icon style="color: white">mdi-close</v-icon>
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-snackbar>
            <vue-element-loading :active="appLoading" :is-full-screen="true" background-color="#FFFFFF" color="#283e51"
                spinner="spinner" />
        </v-layout>
        <v-layout wrap justify-center>
            <v-flex xs11 pt-8 text-left>
                <span style="font-size: 25px;font-weight: bolder;">BOOKING'S TOMORROW</span>
            </v-flex>
        </v-layout>
        <v-layout v-for="(item, i) in list" :key="i" wrap justify-center>
            <v-flex xs12 md11 pt-4>
                <v-card outlined class="pa-3">
                    <v-layout wrap justify-center>
                        <v-flex xs12>
                            <v-layout wrap pa-2>
                                <v-flex xs4 lg2 pl-2>
                                    <v-img height="100%" contain src="../../assets/Images/ibimage12.jpg"></v-img>
                                </v-flex>
                                <v-flex xs8 lg10 pl-4>
                                    <v-layout wrap justify-start>
                                        <v-flex xs12 text-left pt-4 class="mainfont">

                                            <v-layout wrap justify-start>
                                                <v-flex xs3>
                                                    <span style="font-size: 17px;font-weight: bold;">
                                                        IB Name:

                                                    </span>
                                                    <span>
                                                        {{ item.accid.accName }}
                                                    </span>
                                                </v-flex>
                                                <v-flex xs3>
                                                    <span style="font-size: 17px;font-weight: bold;">
                                                        Range:

                                                    </span>
                                                    <span>
                                                        {{ item.accid.rangeid.officeName }}
                                                    </span>
                                                </v-flex>
                                            </v-layout>

                                        </v-flex>
                                        <v-flex xs12 md3 text-left py-2>
                                            <span class="mainfont" style="font-size: 17px">
                                                <span style="font-size: 17px;font-weight: bold;"> Reserved
                                                    For&nbsp;:&nbsp;</span>
                                                <span class="bold-heading">{{ item.guestDetail.guestName }}</span>
                                            </span>
                                        </v-flex>
                                        <v-flex xs12 md3 text-left py-2>
                                            <span class="mainfont" style="font-size: 17px">
                                                <span style="font-size: 17px;font-weight: bold;"> Phone</span>
                                                : <span class="bold-heading">{{ item.guestDetail.guestMobileNo }}</span>
                                            </span>
                                        </v-flex>
                                        <v-flex xs12 md3 text-left py-2>
                                            <span class="mainfont" style="font-size: 17px">
                                                <span style="font-size: 17px;font-weight: bold;"> Required
                                                    Rooms&nbsp;:&nbsp;</span>
                                                {{ item.reqRooms }}
                                            </span>
                                        </v-flex>
                                        <v-flex xs12 md3 text-left py-2>
                                            <span class="mainfont" style="font-size: 17px">
                                                <span style="font-size: 17px;font-weight: bold;"> Booked
                                                    Date&nbsp;:&nbsp;</span>

                                                {{ formatDate(item.selectedDate) }}
                                            </span>
                                        </v-flex>
                                        <v-flex xs12 md3 text-left>
                                            <span class="mainfont" style="font-size: 17px">
                                                <span style="font-size: 17px;font-weight: bold;"> Referred By&nbsp;: </span>
                                                {{ item.guestDetail.referredBy }}
                                            </span>
                                        </v-flex>
                                        <v-flex xs12 md3 text-left>
                                            <span class="mainfont" style="font-size: 17px">
                                                <span style="font-size: 17px;font-weight: bold;"> Accompanying
                                                    Persons&nbsp;: </span>

                                                {{ item.guestDetail.personAccompanyNo }}
                                            </span>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout wrap justify-start>
                                        <v-spacer></v-spacer>
                                        <v-flex xs6 lg2 align-self-end text-right>
                                            <v-btn plain class="text-capitalize"
                                                @click=" (editdialog = true), (edit = item)" color=orange>
                                                Edit
                                            </v-btn>
                                        </v-flex>
                                        <v-flex xs6 lg2 align-self-end text-right>
                                            <v-btn plain class="text-capitalize"
                                                @click="getInfo(item._id), (viewdialog = true)" color="#3CB371">
                                                View more
                                            </v-btn>
                                        </v-flex>
                                        <v-flex xs12 sm6 lg3 align-self-end text-right>
                                            <v-btn plain class="text-capitalize"
                                                @click="setCurIdToDelete(item._id), (deletedialog = true)" color="red">
                                                Cancel Reservation
                                            </v-btn>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                            </v-layout>
                            <v-divider></v-divider>
                        </v-flex>
                    </v-layout>
                </v-card>
            </v-flex>
        </v-layout>
        <v-layout wrap>
            <v-flex xs12 v-if="list.length>0">
                <v-pagination prev-icon="mdi-menu-left" next-icon="mdi-menu-right" :length="pages" :total-visible="7"
                    v-model="currentPage" color="#283e51"></v-pagination>
            </v-flex>
        </v-layout>
        <v-dialog v-model="viewdialog" :max-width="$vuetify.breakpoint.name == 'lg' ||
            $vuetify.breakpoint.name == 'xl'
            ? '75%'
            : '90%'
            ">
            <v-card>
                <v-card-title style="font-family:poppinsregular;font-size:13px">
                    <v-layout wrap justify-center>
                        <v-flex xs4 lg2 align-self-start pt-8>
                            <v-img height="100%" contain src="../../assets/Images/ibimage12.jpg"></v-img>
                        </v-flex>
                        <v-flex xs10 pl-4>
                            <v-layout class="mainfont" wrap fill-height justify-center pt-5>
                                <v-flex xs12>
                                    <v-card style="border-radius: 0px;" elevation="0">
                                        <v-layout wrap>
                                            <v-flex xs4 pl-3 v-if="info.guestDetail" text-left>
                                                <span style="font-weight: bold; color: #3CB371;">Guest Name<br /></span> {{
                                                    info.guestDetail.guestName }}
                                            </v-flex>
                                            <v-flex xs4 pl-3 v-if="info.reqRooms" text-left>
                                                <span style="font-weight: bold; color: #3CB371;">Required Rooms<br /></span>
                                                {{ info.reqRooms
                                                }}
                                            </v-flex>
                                            <v-flex xs4 pl-3 v-if="info.guestDetail" text-left>
                                                <span style="font-weight: bold; color: #3CB371;">Mobile Number<br /></span>
                                                {{
                                                    info.guestDetail.guestMobileNo }}
                                            </v-flex>
                                            <v-flex xs4 pl-3 pt-3 v-if="info.guestDetail" text-left>
                                                <span style="font-weight: bold; color: #3CB371;">Email Address<br /></span>
                                                {{
                                                    info.guestDetail.guestEmailId }}
                                            </v-flex>
                                            <v-flex xs4 pl-3 pt-3 v-if="info.guestDetail" text-left>
                                                <span style="font-weight: bold; color: #3CB371;">Accompanying
                                                    Persons<br /></span> {{
                                                        info.guestDetail.personAccompanyNo }}
                                            </v-flex>
                                            <v-flex xs4 pl-3 pt-3 v-if="info.guestDetail" text-left>
                                                <span style="font-weight: bold; color: #3CB371;">Referred By<br /></span> {{
                                                    info.guestDetail.referredBy }}
                                            </v-flex>
                                            <v-flex xs4 pl-3 pt-3 text-left v-if="info.guestDetail">
                                                <span style="font-weight: bold; color: #3CB371;"> Vehicle Numbers</span>
                                                <span v-for="(vehicleNumber, index) in info.guestDetail.vehicleNumbers"
                                                    :key="index"><br />
                                                    {{ vehicleNumber }}

                                                </span>

                                            </v-flex>
                                            <v-flex xs4 pl-3 pt-3 v-if="info.guestDetail" text-left>
                                                <span style="font-weight: bold; color: #3CB371;">Other Details<br /></span>
                                                {{
                                                    info.guestDetail.anyOtherDetails }}
                                            </v-flex>
                                            <v-flex xs4 pl-3 pt-3 v-if="info.guestDetail" text-left>
                                                <span style="font-weight: bold; color: #3CB371;">Meals Type<br /></span> {{
                                                    info.guestDetail.mealsType }}
                                            </v-flex>
                                            <v-flex xs4 pl-3 pt-3 v-if="info.guestDetail" text-left>
                                                <span style="font-weight: bold; color: #3CB371;">No. of
                                                    Vehicles<br /></span> {{
                                                        info.guestDetail.noOfVehicles }}
                                            </v-flex>
                                            <v-flex xs6 pl-3 pt-3 v-if="info.guestDetail" text-left>
                                                <span style="font-weight: bold; color: #3CB371;">Referred Person Phone
                                                    Number<br /></span> {{
                                                        info.guestDetail.referencePersonPh }}
                                            </v-flex>
                                        </v-layout>
                                    </v-card>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" text @click="viewdialog = false">close</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="editdialog" :max-width="$vuetify.breakpoint.name == 'lg' ||
        $vuetify.breakpoint.name == 'xl'
        ? '75%'
        : '90%'
        ">
        <v-card>
          <v-card-title style="font-family:poppinsregular;font-size:13px">
            <v-layout wrap justify-center>
              <v-flex xs4 lg2 align-self-start pt-8>
                <v-img height="100%" contain src="../../assets/Images/ibimage12.jpg"></v-img>
              </v-flex>
              <v-flex xs10 pl-4>
                <v-layout class="mainfont" wrap fill-height justify-center pt-5>
                  <v-flex xs12>
                    <v-card style="border-radius: 0px;" elevation="0">
                      <v-layout wrap>
                        <v-flex xs4>
                          <span style="font-weight: bold; color: #3CB371;">
                            Selected Date
                          </span>
                          <br />
                          {{ formatDate(edit.fromDate) }} - {{ formatDate(edit.toDate) }}
  
                        </v-flex>
                        <v-flex xs4 v-if="edit.guestDetail">
  
  
                          <span style="font-weight: bold; color: #3CB371;">
                            Reserved for 
                          </span> <br />
                          {{ edit.guestDetail.guestName }}
  
                        </v-flex>
                        <!-- <v-flex xs4 pt-9 v-if="edit.guestDetail">
  <v-btn   color="#3CB043" dark download
    :href="baseURL + 'file/get/' + edit.idfile" target="_blank"><span
      style="text-transform: none; font-family: mainfont; font-size: 12px;">View Pdf</span></v-btn>
  </v-flex> -->
                        <v-flex xs6 pt-3 v-if="edit.guestDetail">
                          <span style="font-weight: bold; color: #3CB371;">Rooms Required</span>
                          <v-text-field color="black" outlined background-color="white" dense type="text"
                           disabled :rules="roomsRules" v-model="edit.reqRooms" hide-details="auto" >
                          </v-text-field>
                        </v-flex>
  

                        <v-flex xs6 pt-3 v-if="edit.guestDetail" pl-3>
                          <span style="font-weight: bold; color: #3CB371;">Guest's Phone no.</span>
                          <v-text-field :rules="guestPhoneRules" color="black" outlined background-color="white" dense
                            type="text" v-model="edit.guestDetail.guestMobileNo" hide-details="auto">
                          </v-text-field>
                        </v-flex>


                        <v-flex xs3 pt-3 v-if="edit.guestDetail">
                          <span style="font-weight: bold; color: #3CB371;">No. of Adults</span>
                          <v-text-field color="black" outlined background-color="white" dense type="text"
                          :rules="noofAdultRules" v-model="edit.guestDetail.noofadults" hide-details="auto">
                          </v-text-field>
                        </v-flex>
  
                        
                        <v-flex xs3 pt-3 v-if="edit.guestDetail" pl-3>
                          <span style="font-weight: bold; color: #3CB371;">No. of Childrens</span>
                          <v-text-field color="black" outlined background-color="white" dense type="text"
                            :rules="noofChildrenRules" v-model="edit.guestDetail.noofchildren" hide-details="auto">
                          </v-text-field>
                        </v-flex>
                        
  
                        
  
                        <!-- <v-flex xs6 pt-3>
                          <span style="font-weight: bold; color: #3CB371;">Date</span>
                          <v-menu ref="menu4" v-model="menu4" :close-on-content-click="false" max-width="290">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field clearable dense outlined readonly v-model="edit.cdate" v-bind="attrs" v-on="on"
                                @click:clear="from = null"></v-text-field>
                            </template>
                            <v-date-picker v-model="edit.cdate" color="#13736f" @change="menu4 = false"
                              :min="minDate"></v-date-picker>
                          </v-menu>
                        </v-flex> -->
  
                        <v-flex xs6 pt-3 pl-3 v-if="edit.guestDetail">
                          <span style="font-weight: bold; color: #3CB371;">Email </span>
                          <v-text-field color="black" outlined background-color="white" dense type="text"
                            :rules="emailRules" v-model="edit.guestDetail.guestEmailId" hide-details="auto">
                          </v-text-field>
                        </v-flex>
  
                        <v-flex xs6 pt-3 v-if="edit.guestDetail">
                          <span style="font-weight: bold; color: #3CB371;">ID Type </span>
                          <v-text-field color="black" outlined background-color="white" dense type="text"
                            disabled v-model="edit.idtype" hide-details="auto">
                          </v-text-field>
                        </v-flex>


                        <!-- <v-flex xs6 pt-3 pl-3 v-if="edit.guestDetail">
                          <span style="font-weight: bold; color: #3CB371;">ID Number </span>
                          <v-text-field color="black" outlined background-color="white" dense type="text"
                            :rules="idRules" v-model="edit.idNo" hide-details="auto" >
                          </v-text-field>
                        </v-flex> -->
              
  
                        <v-flex xs12 v-if="edit.guestDetail">
                          <span style="font-weight: bold; color: #3CB371;">Other details provided
  
                          </span>
                          <v-text-field color="black" outlined background-color="white" type="text"
                            v-model="edit.guestDetail.anyOtherDetails" hide-details>
                          </v-text-field>
                        </v-flex>
  
  
                      </v-layout>
                    </v-card>
                  </v-flex>
                </v-layout>
                <v-layout wrap  v-if="list.length < 1">
                  <v-flex xs12 text-center >
                    <span>No Bookings Found !</span>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-end>
              <v-flex xs1 pl-5 pt-3>
                <v-btn color="red" text @click="editdialog = false">close</v-btn>
  
              </v-flex>
              <v-flex xs1 pl-5 pt-3>
                <v-btn color="green" text @click="editItem()">Update</v-btn>
  
              </v-flex>
  
            </v-layout>
          </v-card-title>
  
        </v-card>
      </v-dialog>
        <v-dialog :retain-focus="true" persistent v-model="deletedialog" max-width="600px">
            <v-card>
                <v-card-title class="mainfont" style="color: black; font-size: 18px; font-weight: lighter">Are you sure you
                    want
                    to Cancel?</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="black" text @click="deletedialog = false" class="mainfont">Cancel</v-btn>
                    <v-btn color="red" class="mainfont" text @click="deleteItem()">OK</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import axios from "axios";
import moment from "moment";

export default {


    data() {
        return {
            itemArray2: ["Vegeterian", "Non-vegetarian"],
            // cdate: moment().format("YYYY-MM-DD"),
            curIdToDelete: null,
            name: null,
            editdialog: false,
            barlist: [],
            edit: [],
            userProfileTabs: null,
            subtab: null,
            appLoading: false,
            page: 1,
            keyArray: [],

            pages: 0,
            dialog1: false,
            preview: null,
            tab: null,
            barlist1: [],
            dialog3: false,
            deletedialog: false,
            minAmount: null,
            password: null,
            categoryarray: [],
            list: [],
            flag: false,
            flag2: false,
            auctionbased: [],
            currentpage: 1,
            menu1: false,
            cdate: null,
            menu2: false,
            menu3: false,
            menu4: false,
            fromDate: null,
            toDate: null,
            fromDate2: null,
            toDate2: null,
            msg: null,
            currentPage: 1,
            showSnackBar: false,
            info: [],
            dialog2: false,
            viewdialog: false,
        };
    },

    mounted() {
        this.getList();
    },
    watch: {
        currentPage() {
            this.getList();
        },
    },
    computed: {
        minDate() {
            const today = new Date();
            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, '0'); // Month is zero-based
            const day = String(today.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        formattedDate() {
            const date = new Date(this.edit.selectedDate);
            return date.toLocaleString("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            });
        },
    },


    methods: {
        setCurIdToDelete(id) {
            this.curIdToDelete = id;
        },
        deleteItem() {
            const idToDelete = this.curIdToDelete;

            if (!idToDelete) {
                return;
            }

            axios({
                url: "/cancel/booking",
                method: "POST",
                headers: {
                    token: localStorage.getItem("token"),
                },
                data: {
                    bookingid: idToDelete,
                },
            })
                .then((response) => {
                    this.appLoading = false;
                    if (response.data.status == true) {
                        this.msg = response.data.msg;
                        this.showSnackBar = true;
                        this.deletedialog = false;
                        window.location.reload();
                    } else {
                        this.msg = response.data.msg;
                        this.showSnackBar = true;
                    }
                })
                .catch((err) => {
                    this.appLoading = false;
                    this.ServerError = true;
                    console.log(err);
                });
        },

        // editItem() {
        //     axios({
        //         url: "/edit/booking",
        //         method: "POST",
        //         headers: {
        //             token: localStorage.getItem("token"),
        //         },
        //         data: {
        //             bookingid: this.edit._id,
        //             guestName: this.edit.guestDetail.guestName,
        //             guestEmailId: this.edit.guestDetail.guestEmailId,
        //             personAccompanyNo: this.edit.guestDetail.personAccompanyNo,
        //             guestMobileNo: this.edit.guestDetail.guestMobileNo,
        //             referredBy: this.edit.guestDetail.referredBy,
        //             referencePersonPh: this.edit.guestDetail.referencePersonPh,
        //             mealsType: this.edit.guestDetail.mealsType,
        //             anyOtherDetails: this.edit.guestDetail.anyOtherDetails,
        //             selectedDate: this.edit.cdate,
        //             vehicleNumbers: this.edit.guestDetail.vehicleNumbers,
        //         },
        //     })
        //         .then((response) => {
        //             this.appLoading = false;
        //             if (response.data.status == true) {
        //                 this.msg = response.data.msg;
        //                 this.showSnackBar = true;
        //                 this.editdialog = false;
        //                 window.location.reload();
        //             } else {
        //                 this.msg = response.data.msg;
        //                 this.showSnackBar = true;
        //             }
        //         })
        //         .catch((err) => {
        //             this.appLoading = false;
        //             this.ServerError = true;
        //             console.log(err);
        //         });
        // },

        editItem() {
  

  axios({
    url: "/edit/booking",
    method: "POST",
      headers: {
      token: localStorage.getItem("token"),
      },
    data: {
      bookingid: this.edit._id,
      reqRooms: this.edit.guestDetail.reqRooms,
      fromDate: this.edit.guestDetail.fromDate,
      toDate: this.edit.guestDetail.toDate,
      guestEmailId: this.edit.guestDetail.guestEmailId,
      noofadults: this.edit.guestDetail.noofadults,
      noofchildren: this.edit.guestDetail.noofchildren,
      guestMobileNo: this.edit.guestDetail.guestMobileNo,
      
      
      anyOtherDetails: this.edit.guestDetail.anyOtherDetails,
    

      idNo: this.edit.guestDetail.idNo,
      idtype: this.edit.guestDetail.idtype,
    },
  })
    .then((response) => {
      this.appLoading = false;
      if (response.data.status == true) {
        this.msg = response.data.msg;
        this.showSnackBar = true;
        this.editdialog = false;
        window.location.reload();
      } else {
        this.msg = response.data.msg;
        this.showSnackBar = true;
      }
    })
    .catch((err) => {
      this.appLoading = false;
      this.ServerError = true;
      console.log(err);
    });
},

        getInfo(id) {
            axios({
                method: "POST",
                url: "/single/booking",
                headers: {
                    token: localStorage.getItem("token"),
                },
                data: {
                    bookingid: id,
                },
            })
                .then((response) => {
                    this.info = response.data.data;
                })
                .catch((err) => {
                    this.ServerError = true;
                    console.log(err);
                });
        },
        formatDate(item) {
            var dt = new Date(item);
            var year = dt.getFullYear();
            dt = dt.toString();
            var strTime = dt.slice(4, 10) + " " + year;
            return strTime;
        },
        getList() {
            this.appLoading = true;
            axios({
                method: "POST",
                url: "/tomorrow/bookings",
                headers: {
                    token: localStorage.getItem("token"),
                },
                data: {
                    page: this.currentPage,
                    fromDate: this.fromDate,
                    toDate: this.toDate,
                    limit: 10,
                },
            })
                .then((response) => {
                    this.list = response.data.data.map((item) => {
                        const date = new Date(item.selectedDate);
                        item.selectedDate = date.toLocaleDateString();
                        item.cdate = moment(item.selectedDate).format("YYYY-MM-DD");
                        return item;
                    });
                    this.totalData = response.data.totalLength;
                    this.selectedDatesArray = this.list.map((item) => item.selectedDate);
                    this.pages = Math.ceil(this.totalData / response.data.limit);
                    this.appLoading = false;
                })
                .catch((err) => {
                    this.ServerError = true;
                    console.log(err);
                });
        },
    },
};
</script>
    
  